import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { AirtableData } from '../../graphql-types';
import {
  AgeBands,
  distanceBands,
  Ethnicities,
  Genders,
  MatchQuestionCategories,
  MatchResponse,
  PossibleDemographicResponseValues,
  PossibleResponseValues,
  pricingBands,
  Sexualities,
} from '../../utils/match';
import QuizButton from '../QuizButton';
import { AwesomeButton } from 'react-awesome-button';

export interface QuestionDisplayProps {
  currentQuestionNumber: number;
  totalQuestions: number;
  question: AirtableData;
  onAnswer: (answer: PossibleResponseValues, dealbreaker?: boolean) => void;
}

type Demographics = 'Sexuality' | 'Age' | 'Gender' | 'Ethnicity';

const getDemographicList = (demo: Demographics): string[] => {
  switch (demo) {
    case 'Sexuality':
      return Object.values(Sexualities);
    case 'Gender':
      return Object.values(Genders);
    case 'Ethnicity':
      return Object.values(Ethnicities);
    case 'Age':
      return Object.values(AgeBands);
    default:
      throw new Error('No match.');
  }
};

const QuestionDisplay: React.FunctionComponent<QuestionDisplayProps> = ({
  currentQuestionNumber,
  totalQuestions,
  question,
  onAnswer,
}): JSX.Element => {
  const [picked, setPicked] = useState<PossibleDemographicResponseValues>([]);
  const [dealbreakerPromptAnswer, setDealbreakerPromptAnswer] = useState<
    PossibleResponseValues
  >(undefined);

  const handleDealbreaker = (
    answer: PossibleResponseValues,
    isDealbreaker: boolean
  ): void => {
    onAnswer(answer, isDealbreaker);
    setPicked([]);
    setDealbreakerPromptAnswer(undefined);
  };

  const questionButtons = (category: MatchQuestionCategories) => {
    switch (category) {
      case MatchQuestionCategories.THERAPY:
        return (
          <>
            <AwesomeButton
              style={{ width: 200 }}
              type="secondary"
              className="mb-3"
              onPress={() => {
                onAnswer(0);
              }}
            >
              Not at all
            </AwesomeButton>
            <AwesomeButton
              style={{ width: 200 }}
              type="secondary"
              className="mb-3"
              onPress={() => {
                onAnswer(1);
              }}
            >
              Sometimes
            </AwesomeButton>
            <AwesomeButton
              style={{ width: 200 }}
              type="secondary"
              className="mb-3"
              onPress={() => {
                onAnswer(2);
              }}
            >
              Regularly
            </AwesomeButton>
            <AwesomeButton
              style={{ width: 200 }}
              type="secondary"
              className="mb-3"
              onPress={() => {
                onAnswer(3);
              }}
            >
              Most of the time
            </AwesomeButton>
          </>
        );
      case MatchQuestionCategories.SPECIALISM:
        return (
          <>
            <AwesomeButton
              style={{ width: 200 }}
              type="secondary"
              className="mb-3"
              onPress={() => {
                onAnswer(0);
              }}
            >
              Not at all
            </AwesomeButton>
            <AwesomeButton
              style={{ width: 200 }}
              type="secondary"
              className="mb-3"
              onPress={() => {
                onAnswer(1);
              }}
            >
              Sometimes
            </AwesomeButton>
            <AwesomeButton
              style={{ width: 200 }}
              type="secondary"
              className="mb-3"
              onPress={() => {
                onAnswer(2);
              }}
            >
              Regularly
            </AwesomeButton>
            <AwesomeButton
              style={{ width: 200 }}
              type="secondary"
              className="mb-3"
              onPress={() => {
                onAnswer(3);
              }}
            >
              Most of the time
            </AwesomeButton>
          </>
        );
      case MatchQuestionCategories.DEMOGRAPHIC:
        return (
          <>
            {getDemographicList(question.SpecificArea as Demographics).map<
              JSX.Element
            >((option: string) => (
              <AwesomeButton
                key={option}
                style={{ width: 200 }}
                type="secondary"
                className="mb-3"
                onPress={() =>
                  picked.includes(option)
                    ? setPicked(picked.filter(y => y !== option))
                    : setPicked([option, ...picked])
                }
                active={picked.includes(option)}
                color={picked.includes(option) ? 'primary' : undefined}
              >
                {RegExp(/^\d{2}$/).test(option) ? `${option}+` : option}
              </AwesomeButton>
            ))}
            <AwesomeButton
              style={{ width: 200 }}
              type="secondary"
              className="mb-3"
              onPress={() => onAnswer([])}
            >
              No Preference
            </AwesomeButton>
            <AwesomeButton
              style={{ width: 200 }}
              type="primary"
              className="mb-3"
              onPress={() => {
                if (!picked.length) {
                  return;
                }
                onAnswer(picked, true);
              }}
              disabled={!picked.length}
            >
              Submit
            </AwesomeButton>
          </>
        );
      case MatchQuestionCategories.LOCATION:
        return (
          <>
            {distanceBands.map(option => (
              <AwesomeButton
                key={option}
                style={{ width: 200 }}
                type="secondary"
                className="mb-3"
                onPress={() => {
                  onAnswer(option, true);
                }}
              >
                {option}km away
              </AwesomeButton>
            ))}
            <AwesomeButton
              color="danger"
              style={{ width: 200 }}
              type="secondary"
              className="mb-3"
              onPress={() => onAnswer(0)}
            >
              No Preference
            </AwesomeButton>
          </>
        );
      case MatchQuestionCategories.PRICING:
        return (
          <>
            {pricingBands.map(option => (
              <AwesomeButton
                key={option}
                style={{ width: 200 }}
                type="secondary"
                className="mb-3"
                onPress={() => {
                  onAnswer(option, true);
                }}
              >
                £{option}
              </AwesomeButton>
            ))}
            <AwesomeButton
              style={{ width: 200 }}
              type="secondary"
              className="mb-3"
              onPress={() => onAnswer(0)}
            >
              No Preference
            </AwesomeButton>
          </>
        );
    }
  };

  return (
    <>
      {questionButtons(
        question.CategoryLink[0].data.CategoryName as MatchQuestionCategories
      )}
    </>
  );
};

export default QuestionDisplay;
