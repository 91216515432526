import { groupBy, map, mapValues, pickBy, sumBy } from 'lodash-es';
import {
  Demographics,
  MatchResponse,
  Specialisms,
  TherapyTypes,
  UserMatchingRecommendation,
} from './match';

const preProcessTherapy = (
  therapyResponses: MatchResponse[]
): TherapyTypes[] => {
  const THERAPY_THRESHOLD = 5;

  const responsesTherapyType = groupBy(therapyResponses, 'specificArea');

  const therapyScores = mapValues(responsesTherapyType, x => sumBy(x, 'value'));

  const therapies: string[] = Object.keys(
    pickBy(therapyScores, (v, k) => v >= THERAPY_THRESHOLD)
  );

  return therapies as TherapyTypes[];
};
const preProcessSpecialism = (
  specialismResponses: MatchResponse[]
): Specialisms[] => {
  const SPECIALISM_THERSHOLD = 2;

  const responsesSpecialismType = groupBy(specialismResponses, 'specificArea');

  const specialismScores = mapValues(responsesSpecialismType, x =>
    sumBy(x, 'value')
  );

  const specialisms: string[] = Object.keys(
    pickBy(specialismScores, (v, k) => v >= SPECIALISM_THERSHOLD)
  );
  return specialisms as Specialisms[];
};
const preProcessDemographic = (demographicResponses: MatchResponse[]) => {
  const demographics = map(demographicResponses, x => {
    return {
      [x.specificArea.toLowerCase()]: {
        value: x.value,
        dealbreaker: x.dealbreaker,
      },
    };
  }).reduce((a, b) => Object.assign(a, b));

  return demographics;
};
export const preProcessResponses = (
  responses: MatchResponse[]
): UserMatchingRecommendation => {
  const categoryGroupedResponse = groupBy<MatchResponse>(responses, 'category');

  const therapies = preProcessTherapy(categoryGroupedResponse.Therapy);
  const specialisms = preProcessSpecialism(categoryGroupedResponse.Specialism);
  const demographic = preProcessDemographic(
    categoryGroupedResponse.Demographic
  );

  return {
    therapies,
    specialisms,
    demographic: (demographic as unknown) as Demographics,
    pricing: {
      value: categoryGroupedResponse.Pricing[0].value as number,
      dealbreaker: categoryGroupedResponse.Pricing[0].dealbreaker,
    },
    distance: {
      value: categoryGroupedResponse.Location[0].value as number,
      dealbreaker: categoryGroupedResponse.Location[0].dealbreaker,
    },
  };
};
