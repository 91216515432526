import React from 'react';
import styled from 'styled-components';
import Particles, { IParticlesParams } from 'react-particles-js';
import { Container } from 'reactstrap';
import { lighten } from 'polished';

const config: IParticlesParams = {
  particles: {
    number: {
      value: 80,
      density: {
        enable: true,
        value_area: 2164.606282168456,
      },
    },
    color: {
      value: '#ffffff',
    },
    shape: {
      type: 'circle',
      stroke: {
        width: 0,
        color: '#000000',
      },
      polygon: {
        nb_sides: 5,
      },
      image: {
        src: 'img/github.svg',
        width: 100,
        height: 100,
      },
    },
    opacity: {
      value: 0.1122388442605866,
      random: false,
      anim: {
        enable: false,
        speed: 1,
        opacity_min: 0.1,
        sync: false,
      },
    },
    size: {
      value: 50,
      random: false,
      anim: {
        enable: false,
        speed: 40,
        size_min: 0.1,
        sync: false,
      },
    },
    line_linked: {
      enable: false,
      distance: 150,
      color: '#ffffff',
      opacity: 0.4,
      width: 1,
    },
    move: {
      enable: true,
      speed: 1.603412060865523,
      direction: 'none',
      random: false,
      straight: false,
      out_mode: 'out',
      bounce: false,
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 1200,
      },
    },
  },
  interactivity: {
    detect_on: 'canvas',
    events: {
      onhover: {
        enable: false,
        mode: 'repulse',
      },
      onclick: {
        enable: false,
        mode: 'push',
      },
      resize: true,
    },
    modes: {
      grab: {
        distance: 400,
        line_linked: {
          opacity: 1,
        },
      },
      bubble: {
        distance: 400,
        size: 40,
        duration: 2,
        opacity: 8,
        // speed: 3,
      },
      repulse: {
        distance: 200,
        duration: 0.4,
      },
      push: {
        particles_nb: 4,
      },
      remove: {
        particles_nb: 2,
      },
    },
  },
  retina_detect: true,
};

interface ParticlesConstituentProps {
  particlesColour: string;
}

export interface ParticlesBackgroundProps extends ParticlesConstituentProps {}

const Wrapper = styled.div<ParticlesConstituentProps>`
  height: 100vh;
  width: 100%;
  background: linear-gradient(
    180deg,
    ${props => props.particlesColour} 0%,
    ${props => lighten(0.2, props.particlesColour)} 100%
  );
  position: relative;
  overflow-y: scroll;
`;

const StyledParticles = styled(Particles)<ParticlesConstituentProps>`
  background: linear-gradient(
    180deg,
    ${props => props.particlesColour} 0%,
    ${props => lighten(0.2, props.particlesColour)} 100%
  );
  filter: blur(5px);
`;

const ParticlesBackground: React.FunctionComponent<ParticlesBackgroundProps> = ({
  particlesColour,
  children,
}): JSX.Element => {
  return (
    <Wrapper particlesColour={particlesColour}>
      <StyledParticles
        particlesColour={particlesColour}
        className="vh-100 vw-100 position-absolute"
        params={config}
      />
      {children}
    </Wrapper>
  );
};

export default ParticlesBackground;
