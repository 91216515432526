import React from 'react';
import { graphql, Link, navigate } from 'gatsby';
import _ from 'lodash';
import urljoin from 'url-join';
import Layout from '../components/Layout';
import PostCard from '../components/PostCard/postCard';
import PostDetails from '../components/PostDetails/postDetails';
import Text from '../reusecore/src/elements/Text/text';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';
import { IoLogoFacebook, IoLogoTwitter, IoLogoLinkedin } from 'react-icons/io';
import {
  BlogPostDetailsWrapper,
  RelatedPostWrapper,
  RelatedPostItems,
  RelatedPostTitle,
  RelatedPostItem,
  BlogPostFooter,
  PostShare,
  PostTags,
  BlogDetailsContent,
} from './templates.style';
import { ArticleQuery, Query } from '../graphql-types';
import { Col, Container, Row } from 'reactstrap';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { articleRichTextOptions } from '../utils/contentful';
import moment from 'moment';
import Image from '../reusecore/src/elements/Image';
import Box from '../reusecore/src/elements/Box';
import BackgroundImage from 'gatsby-background-image';
import { faAngleDown, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { theme } from '../theme';
import styled from 'styled-components';
import SEO from '../components/SEO';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getFirebaseAuth } from '../services/firebase';

export const HorizontalRule = styled.div`
  background-color: #dbe3e7;
  border-style: none;
  color: #536171;
  height: 1px;
  line-height: 16px;
  margin: 0;
  width: 100%;
`;

export interface ArticleTemplateProps {
  data: ArticleQuery;
}

const AuthorText = styled(Text)`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const ArticleTemplate: React.FunctionComponent<ArticleTemplateProps> = ({
  data,
}) => {
  const [user, loading, error] = useAuthState(getFirebaseAuth());

  const shareUrl = 'https://www.helsahelps.com';

  const { contentfulArticle, allContentfulArticle } = data;

  let richTextComponent;
  if (data.contentfulArticle.richTextBody) {
    richTextComponent = documentToReactComponents(
      data.contentfulArticle.richTextBody.json,
      articleRichTextOptions
    );
  }

  const overlayColour = `rgba(0,0,0,${
    data.contentfulArticle.overlayOpacityPercentage
      ? data.contentfulArticle.overlayOpacityPercentage / 100
      : 0
  })`;

  return (
    <Layout transparentNav barsOnly>
      <SEO
        meta={{}}
        description={data.contentfulArticle.subtitle}
        metaImage={{
          src: data.facebookCardImage.shareImage
            ? `https:${data.facebookCardImage.shareImage.fixed.src}`
            : `https:${data.facebookCardImage.coverImage.fixed.src}`,
          height: 630,
          width: 1200,
        }}
        title={data.contentfulArticle.title}
      />
      <BackgroundImage
        alt="title"
        style={{
          height: '100vh',
          width: '100%',
          position: 'absolute',
          top: '0',
        }}
        fluid={data.contentfulArticle.coverImage.fluid}
      >
        <Box
          position="absolute"
          width="100%"
          height="100%"
          bg={overlayColour}
        />
        <Container
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            zIndex: 10,
          }}
        >
          <Text
            as="h2"
            fontSize={[7, 9]}
            fontFamily="heading"
            color="white"
            m={0}
            pb={2}
            fontWeight={4}
            textAlign="center"
            lineHeight="title"
          >
            {data.contentfulArticle.title}
          </Text>
          <Text
            display="inline-block"
            as="p"
            fontSize={2}
            fontFamily="heading"
            fontWeight={1}
            m={0}
            pb={2}
            color="white"
            textAlign="center"
          >
            {data.contentfulArticle.subtitle}
          </Text>
          <Text
            as="p"
            fontSize={1}
            fontFamily="body"
            fontWeight={1}
            m={0}
            color="white"
            textAlign="center"
          >
            By{' '}
            {contentfulArticle.therapist
              ? contentfulArticle.therapist.fullName
              : contentfulArticle.authorName}{' '}
            • {moment(contentfulArticle.publishedDate).format('Do MMMM YYYY')}
          </Text>
          <Text
            as="p"
            fontSize={11}
            fontFamily="body"
            fontWeight={1}
            m={0}
            color="white"
            textAlign="center"
          >
            {contentfulArticle.imageCredit}
          </Text>
          <Box
            flexBox
            justifyContent={'center'}
            position="absolute"
            width="100%"
            bottom="0"
            pb={[0, 3]}
          >
            <FontAwesomeIcon
              className="mr-2"
              icon={faAngleDown}
              color="white"
              size="5x"
            />
          </Box>
        </Container>
      </BackgroundImage>
      <Container fluid style={{ backgroundColor: theme.colors.sand }}>
        <Container style={{ paddingBottom: theme.space[6] }}>
          <Row>
            <Col xs={12} sm={{ size: 8, offset: 2 }}>
              <Box height="calc(100vh - 70px)" width="100%" mb={3} />
              <Box py={4}>
                <Box
                  flexBox
                  flexDirection={['column', 'row']}
                  alignItems={['flex-start', 'center']}
                >
                  <Image
                    src={
                      contentfulArticle.therapist
                        ? contentfulArticle.therapist.profilePhoto.resize.src
                        : contentfulArticle.authorPhoto
                        ? contentfulArticle.authorPhoto.resize.src
                        : null
                    }
                    alt="Author image"
                    width={7}
                    height={7}
                    borderRadius={[2, '50%']}
                    mr={3}
                    mb={[3, 0]}
                  />

                  <Box flexBox flexDirection="column">
                    {user && contentfulArticle.therapist ? (
                      <AuthorText
                        m={0}
                        fontFamily="heading"
                        fontSize={[2, 3]}
                        fontWeight={3}
                        pb={0}
                        lineHeight="title"
                        onClick={() => {
                          navigate(
                            `/therapist/${contentfulArticle.therapist.slug}`
                          );
                        }}
                      >
                        {contentfulArticle.therapist
                          ? contentfulArticle.therapist.fullName
                          : contentfulArticle.authorName}
                      </AuthorText>
                    ) : (
                      <Text
                        m={0}
                        fontFamily="heading"
                        fontSize={[2, 3]}
                        fontWeight={3}
                        pb={0}
                        lineHeight="title"
                      >
                        {contentfulArticle.therapist
                          ? contentfulArticle.therapist.fullName
                          : contentfulArticle.authorName}
                      </Text>
                    )}
                    <Text
                      m={0}
                      fontFamily="heading"
                      fontSize={0}
                      fontWeight={0}
                      pb={2}
                    >
                      {contentfulArticle.therapist
                        ? contentfulArticle.therapist.title
                        : contentfulArticle.authorTitle}
                    </Text>
                  </Box>
                </Box>
              </Box>
              <Box pb={4}>
                <Text m={0} ml={1} fontWeight={3}>
                  Share:
                </Text>
                <FacebookShareButton
                  style={{ marginRight: theme.space[2] }}
                  url={`https://www.helsahelps.com/article/${contentfulArticle.slug}`}
                >
                  <IoLogoFacebook size={32} color="#4064ad" />
                </FacebookShareButton>
                <TwitterShareButton
                  style={{ marginRight: theme.space[2] }}
                  url={`https://www.helsahelps.com/article/${contentfulArticle.slug}`}
                >
                  <IoLogoTwitter size={32} color="#1b9eeb" />
                </TwitterShareButton>
                <LinkedinShareButton
                  style={{ marginRight: theme.space[2] }}
                  url={`https://www.helsahelps.com/article/${contentfulArticle.slug}`}
                >
                  <IoLogoLinkedin size={32} color="#0578b1" />
                </LinkedinShareButton>
              </Box>
              {richTextComponent}
              {contentfulArticle.tags && (
                <Box flexBox mb={4}>
                  <Text
                    fontFamily="body"
                    fontSize="1rem"
                    fontWeight={400}
                    mb={0}
                    mr={4}
                    textAlign="left"
                    lineHeight="copy"
                  >
                    Tags:
                  </Text>
                  <PostTags className="post_tags">
                    {contentfulArticle.tags.map(
                      (tag: string, index: number) => (
                        <Link key={index} to={`/tags/${_.kebabCase(tag)}/`}>
                          {`${tag}`}
                        </Link>
                      )
                    )}
                  </PostTags>
                </Box>
              )}
            </Col>
          </Row>
        </Container>
      </Container>

      {allContentfulArticle.edges.length !== 0 && (
        <RelatedPostWrapper
          style={{ paddingBottom: theme.space[5], paddingTop: theme.space[5] }}
        >
          <RelatedPostTitle>More Articles</RelatedPostTitle>
          <RelatedPostItems>
            {allContentfulArticle.edges.slice(0, 3).map(({ node }: any) => {
              //Random Placeholder Color
              const placeholderColors = [
                '#55efc4',
                '#81ecec',
                '#74b9ff',
                '#a29bfe',
                '#ffeaa7',
                '#fab1a0',
                '#e17055',
                '#0984e3',
                '#badc58',
                '#c7ecee',
              ];
              const setColor =
                placeholderColors[
                  Math.floor(Math.random() * placeholderColors.length)
                ];
              return (
                <RelatedPostItem key={node.slug}>
                  <PostCard
                    title={node.title}
                    url={`/article/${node.slug}`}
                    image={
                      node.coverImage == null
                        ? null
                        : node.coverImage.resize.src
                    }
                    imageType="resize"
                    placeholderBG={setColor}
                  />
                </RelatedPostItem>
              );
            })}
          </RelatedPostItems>
        </RelatedPostWrapper>
      )}
    </Layout>
  );
};

export default ArticleTemplate;

export const pageQuery = graphql`
  query Article($slug: String!) {
    contentfulArticle(slug: { eq: $slug }) {
      title
      subtitle
      slug
      authorName
      authorTitle
      publishedDate
      authorPhoto {
        resize(
          height: 300
          width: 300
          jpegProgressive: true
          resizingBehavior: FILL
        ) {
          src
        }
      }
      imageCredit
      tags

      therapist {
        fullName
        title
        slug
        profilePhoto {
          resize(
            height: 300
            width: 300
            jpegProgressive: true
            resizingBehavior: FILL
          ) {
            src
          }
        }
      }
      richTextBody {
        json
        content {
          data {
            target {
              fields {
                file {
                  en_US {
                    url
                  }
                }
              }
            }
          }
        }
      }
      coverImage {
        fluid(maxWidth: 1500) {
          ...GatsbyContentfulFluid_noBase64
        }
        resize(
          width: 900
          height: 450
          jpegProgressive: true
          resizingBehavior: FILL
        ) {
          src
        }
      }
      overlayOpacityPercentage
    }
    allContentfulArticle {
      edges {
        node {
          id
          slug
          title
          publishedDate
          coverImage {
            resize(
              width: 900
              height: 450
              jpegProgressive: true
              resizingBehavior: FILL
            ) {
              src
            }
          }
        }
      }
    }

    facebookCardImage: contentfulArticle(slug: { eq: $slug }) {
      coverImage {
        fixed(
          resizingBehavior: FILL
          cropFocus: CENTER
          toFormat: JPG
          height: 630
          width: 1200
          quality: 90
        ) {
          src
        }
      }
      shareImage {
        fixed(
          resizingBehavior: FILL
          cropFocus: CENTER
          toFormat: JPG
          height: 630
          width: 1200
          quality: 90
        ) {
          src
        }
      }
    }
    twitterCardImage: contentfulArticle(slug: { eq: $slug }) {
      coverImage {
        fixed(
          resizingBehavior: THUMB
          toFormat: PNG
          height: 300
          width: 300
          cropFocus: FACE
        ) {
          src
        }
      }
    }
  }
`;
