import React from 'react';
import EvaluationAuth from '../screens/auth/EvaluationAuth';
import { Redirect, Router } from '@reach/router';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getFirebaseAuth } from '../services/firebase';
import MatchAuth from '../screens/auth/MatchAuth';

export interface MatchPageProps {}

const MatchPage: React.FunctionComponent<MatchPageProps> = (): JSX.Element => {
  const [user, loading, error] = useAuthState(getFirebaseAuth());

  if (loading) {
    return <></>;
  }

  return user ? <MatchAuth /> : <Redirect noThrow to="/" />;
};

export default MatchPage;
