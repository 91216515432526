import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Col, Container, Row } from 'reactstrap';
import { navigate } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import { AirtableData, MatchingQuizQuery, Query } from '../../graphql-types';
import firebase from 'firebase';
import { useSession } from '../../utils/firebase-hooks';
import {
  CategoryWeights,
  Ethnicities,
  Genders,
  MatchOutput,
  MatchResponse,
  PossibleResponseValues,
  Sexualities,
  Specialisms,
  TherapistInput,
  TherapyTypes,
  UserMatchingCriteria,
  UserMatchingRecommendation,
  userTherapistMatch,
} from '../../utils/match';
import QuestionDisplay from '../../components/QuestionDisplay';
import moment from 'moment';
import { useDocumentOnce } from 'react-firebase-hooks/firestore';
import { shuffle, sortBy } from 'lodash-es';
import Layout from '../../components/Layout';
import ParticlesBackground from '../../components/ParticlesBackground';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { navigate as gatsbyNavigate } from 'gatsby-link';
import { preProcessResponses } from '../../utils/preprocess';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getFirebaseAuth, getFirebaseFirestore } from '../../services/firebase';
import { getCategoryColour } from './EvaluationAuth';
import Text from '../../reusecore/src/elements/Text/text';
import Button from '../../reusecore/src/elements/Button/button';
import Box from '../../reusecore/src/elements/Box';
import { theme } from '../../theme';
import Modali, { useModali } from 'modali';
import { HorizontalRule } from '../../templates/article';

export type MatchResultsFirebase = {
  creationDate: firebase.firestore.Timestamp | string;
  resultsObject: MatchOutput[];
  recommendations: UserMatchingCriteria;
};
export type MatchResponsesFirebase = {
  creationDate: firebase.firestore.Timestamp | string;
  responses: MatchResponse[];
};

export interface MatchAuthProps {}

const MatchAuth: React.FunctionComponent<MatchAuthProps> = (): JSX.Element => {
  const {
    rawQuestionSet: { edges: matchQuestionsRawData },
    rawCategoryInfo: { edges: categoryRawData },
    allContentfulTherapist,
  } = useStaticQuery<MatchingQuizQuery>(graphql`
    query MatchingQuiz {
      rawQuestionSet: allAirtable(filter: { table: { eq: "Questions" } }) {
        edges {
          node {
            data {
              QuestionID
              QuestionText
              SpecificArea
              CategoryLink {
                data {
                  CategoryName
                  Weight
                }
              }
            }
          }
        }
      }
      rawCategoryInfo: allAirtable(filter: { table: { eq: "Categories" } }) {
        edges {
          node {
            data {
              CategoryName
              Weight
            }
          }
        }
      }
      allContentfulTherapist(filter: { currentlyAvailable: { eq: true } }) {
        edges {
          node {
            slug
            therapyTypes
            specialisms
            dateOfBirth
            sexuality
            gender
            ethnicity
            sessionPricing
            location {
              lat
              lon
            }
          }
        }
      }
    }
  `);
  const matchQuestionsDataSorted = useMemo<AirtableData[]>(
    () =>
      matchQuestionsRawData
        .map<AirtableData>(x => x.node.data as AirtableData)
        .sort((a, b) => a.QuestionID - b.QuestionID),
    [matchQuestionsRawData]
  );
  const categoryData = categoryRawData.map<AirtableData>(x => x.node.data);
  const weights: CategoryWeights = (categoryData
    .map(x => ({
      [x.CategoryName]: x.Weight,
    }))
    .reduce((accumulator, current) =>
      Object.assign({}, accumulator, current)
    ) as unknown) as CategoryWeights;

  const therapists = allContentfulTherapist.edges.map<TherapistInput>(x => ({
    therapistId: x.node.slug,
    therapies: x.node.therapyTypes as TherapyTypes[],
    specialisms: x.node.specialisms as Specialisms[],
    demographic: {
      gender: x.node.gender as Genders,
      sexuality: x.node.sexuality as Sexualities,
      ethnicity: x.node.ethnicity as Ethnicities,
      age: moment().diff(moment(x.node.dateOfBirth), 'years'),
    },
    pricing: x.node.sessionPricing,
    location: [x.node.location.lat, x.node.location.lon],
  }));

  // Variable initialisation.

  const [user, loading, error] = useAuthState(getFirebaseAuth());

  const db = getFirebaseFirestore();

  const [userSnapshot, userLoading, userError] = useDocumentOnce(
    !db ? null : db.collection('users').doc(user.uid)
  );

  const totalQuestions = matchQuestionsDataSorted.length;

  const [processing, setProcessing] = useState<boolean>(false);
  const [processingError, setProcessingError] = useState<boolean>(false);
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState<number>(1);
  const currentQuestion = matchQuestionsDataSorted[currentQuestionNumber - 1];
  const [responses, setResponses] = useState<MatchResponse[]>([]);
  const [matchDebug, setMatchDebug] = useState<boolean>(false);
  const [debugResults, setDebugResults] = useState([]);
  const [dealbreaker, setDealbreaker] = useState<PossibleResponseValues>(null);

  const [dealbreakerModal, toggleDealbreakerModal] = useModali({
    animated: true,
    centered: true,
    overlayClose: false,
    closeButton: false,
  });

  const handleAnswer = (
    answer: PossibleResponseValues,
    dealbreakerQuestion?: boolean
  ): void => {
    if (dealbreakerQuestion) {
      setDealbreaker(answer);
      return;
    }
    setResponses([
      ...responses,
      {
        questionId: currentQuestion.QuestionID,
        category: currentQuestion.CategoryLink[0].data.CategoryName,
        specificArea: currentQuestion.SpecificArea,
        value: answer,
        dealbreaker: !!dealbreakerQuestion,
      },
    ]);
    if (currentQuestionNumber !== totalQuestions) {
      setCurrentQuestionNumber(currentQuestionNumber + 1);
    }
  };

  useEffect(() => {
    if (!!dealbreaker) {
      toggleDealbreakerModal();
    }
  }, [dealbreaker]);

  const handleBack = (): void => {
    setResponses(responses.slice(0, -1));
    setCurrentQuestionNumber(currentQuestionNumber - 1);
  };

  useEffect(() => {
    if (responses.length >= totalQuestions && !matchDebug) {
      setProcessing(true);
      const recsInput: UserMatchingCriteria = {
        ...preProcessResponses(responses),
        location: [userSnapshot.data().lat, userSnapshot.data().long],
      };
      const results: MatchOutput[] = therapists.map<MatchOutput>(x =>
        userTherapistMatch(recsInput, x, weights)
      );
      const topResults: MatchOutput[] = sortBy<MatchOutput>(
        results,
        'score'
      ).reverse();
      const timestamp = new Date();
      const matchResultsRef = db
        .collection('users')
        .doc(user.uid)
        .collection('matchResults')
        .doc(timestamp.toISOString());
      matchResultsRef
        .set({
          creationDate: timestamp.toISOString(),
          resultsObject: topResults,
          recommendations: recsInput,
        } as MatchResultsFirebase)
        .then<void>(() => {
          const matchResponsesRef = db
            .collection('users')
            .doc(user.uid)
            .collection('matchResponses')
            .doc(timestamp.toISOString());
          matchResponsesRef
            .set({
              creationDate: timestamp.toISOString(),
              responses,
            } as MatchResponsesFirebase)
            .then<void>(() => {
              navigate('/matches');
            })
            .catch(err => {
              setProcessingError(true);
            });
        });
    }
  }, [responses, totalQuestions, userSnapshot, therapists, weights]);

  return (
    <Layout contrast>
      <ParticlesBackground particlesColour={getCategoryColour('minority')}>
        <Container
          fluid
          className="position-absolute vh-100 vw-100 d-flex flex-column align-items-center"
          style={{ zIndex: 10 }}
        >
          <Row className="pt-3 pt-sm-5">
            <Col xs={{ size: 6, offset: 3 }}>
              <CircularProgressbar
                value={((currentQuestionNumber - 1) / totalQuestions) * 100}
                text={`Q${currentQuestionNumber}`}
                strokeWidth={14}
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  rotation: 0,

                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: 'butt',

                  // Text size
                  textSize: '24px',

                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 0.5,

                  // Can specify path transition in more detail, or remove it entirely
                  // pathTransition: 'none',

                  // Colors
                  pathColor: `white`,
                  textColor: 'white',
                  trailColor: 'rgba(214, 214, 214, 0.4)',
                  backgroundColor: 'red',
                })}
              />
            </Col>
          </Row>
          {processing ? (
            <Row>
              <Col className="text-center mt-3">
                <h3 className="text-white">
                  {processingError
                    ? 'Processing error.'
                    : 'Processing your results...'}
                </h3>
              </Col>
            </Row>
          ) : (
            <>
              <Row>
                <Col className="text-center">
                  <Text
                    style={{ maxWidth: 600 }}
                    fontFamily="heading"
                    fontWeight={2}
                    fontSize={2}
                    color="white"
                  >
                    {currentQuestion.QuestionText}
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex flex-column text-center align-items-center justify-content-center">
                  <QuestionDisplay
                    currentQuestionNumber={currentQuestionNumber}
                    totalQuestions={totalQuestions}
                    question={currentQuestion}
                    onAnswer={handleAnswer}
                  />
                </Col>
              </Row>
            </>
          )}
        </Container>
        <Modali.Modal {...dealbreakerModal}>
          <Box
            flexBox
            width="100%"
            height="100%"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            px={4}
            py={4}
          >
            <Text
              m={0}
              fontSize={2}
              fontWeight={3}
              fontFamily="heading"
              pb={3}
              textAlign="center"
            >
              Is this a dealbreaker?
            </Text>
            <HorizontalRule />
            <Text
              m={0}
              fontSize={0}
              fontWeight={1}
              fontFamily="body"
              pb={3}
              pt={3}
              textAlign="center"
            >
              Selecting a dealbreaker will filter out any therapists that don't
              match your response.
            </Text>
            <Button
              px={4}
              mt={3}
              width={8}
              onClick={() => {
                setResponses([
                  ...responses,
                  {
                    questionId: currentQuestion.QuestionID,
                    category: currentQuestion.CategoryLink[0].data.CategoryName,
                    specificArea: currentQuestion.SpecificArea,
                    value: dealbreaker,
                    dealbreaker: true,
                  },
                ]);
                if (currentQuestionNumber !== totalQuestions) {
                  setCurrentQuestionNumber(currentQuestionNumber + 1);
                }
                setDealbreaker(null);
                toggleDealbreakerModal();
              }}
            >
              <Text as="span">Yes</Text>
            </Button>
            <Button
              width={8}
              px={4}
              mt={3}
              onClick={() => {
                setResponses([
                  ...responses,
                  {
                    questionId: currentQuestion.QuestionID,
                    category: currentQuestion.CategoryLink[0].data.CategoryName,
                    specificArea: currentQuestion.SpecificArea,
                    value: dealbreaker,
                    dealbreaker: false,
                  },
                ]);
                if (currentQuestionNumber !== totalQuestions) {
                  setCurrentQuestionNumber(currentQuestionNumber + 1);
                }
                setDealbreaker(null);
                toggleDealbreakerModal();
              }}
            >
              <Text as="span">No</Text>
            </Button>
          </Box>
        </Modali.Modal>
      </ParticlesBackground>
    </Layout>
  );
};

export default MatchAuth;
