import React, { useEffect, useState } from 'react';
import { graphql, navigate as gatsbyNavigate, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { Col, Container, Row } from 'reactstrap';
import { navigate, RouteComponentProps } from '@reach/router';
import { number } from 'yup';
import { AirtableData, DiagnosisQuizQuery, Query } from '../../graphql-types';
import Layout from '../../components/Layout';
import ParticlesBackground from '../../components/ParticlesBackground';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import QuestionDisplay from '../../components/QuestionDisplay';
// @ts-ignore
import { AwesomeButton } from 'react-awesome-button';
import Modali, { useModali } from 'modali';
import { darken, lighten } from 'polished';
import colors from '../../theme/colors';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getFirebaseAuth, getFirebaseFirestore } from '../../services/firebase';
import Text from '../../reusecore/src/elements/Text/text';
import Box from '../../reusecore/src/elements/Box';
import { HorizontalRule } from '../../templates/article';
import Button from '../../reusecore/src/elements/Button/button';

export type Categories = 'anxiety' | 'depression' | 'stress' | 'minority';

const getFormattedCategoryTitle = (category: Categories) => {
  switch (category) {
    case 'anxiety':
      return 'Anxiety';
    case 'depression':
      return 'Depression';
    case 'stress':
      return 'Stress';
    case 'minority':
      return 'Minority Stress';
    default:
      throw new Error('No match found.');
  }
};

const getFormattedCategoryPhrase = (category: Categories) => {
  switch (category) {
    case 'anxiety':
      return 'Anxiety';
    case 'depression':
      return 'Depression';
    case 'stress':
      return 'Stress';
    case 'minority':
      return 'minority stress';
    default:
      throw new Error('No match found.');
  }
};

export const getCategoryColour = (category: Categories): string => {
  switch (category) {
    case 'anxiety':
      return darken(0.15, colors.anxiety);
    case 'depression':
      return darken(0.1, colors.depression);
    case 'stress':
      return darken(0.1, colors.stress);
    case 'minority':
      return darken(0.1, colors.minorityStress);
    default:
      throw new Error('No match found.');
  }
};

export interface DiagnosisQuizPageProps
  extends RouteComponentProps<{
    category: Categories;
  }> {}

const getSeverityLevel = (score: number): number => {
  if (score <= 4) {
    return 1;
  } else if (score <= 9) {
    return 2;
  } else if (score <= 14) {
    return 3;
  } else if (score <= 21) {
    return 4;
  } else {
    throw new Error('Severity not matched.');
  }
};

const DiagnosisQuizPage: React.FunctionComponent<DiagnosisQuizPageProps> = ({
  category,
}): JSX.Element => {
  const {
    anxietyRaw: { edges: anxietyQuestionsRaw },
    depressionRaw: { edges: depressionQuestionsRaw },
    stressRaw: { edges: stressQuestionsRaw },
    minorityRaw: { edges: minorityQuestionsRaw },
  } = useStaticQuery<DiagnosisQuizQuery>(graphql`
    query DiagnosisQuiz {
      anxietyRaw: allAirtable(filter: { table: { eq: "Anxiety" } }) {
        edges {
          node {
            data {
              QuestionNumber
              QuestionText
            }
          }
        }
      }
      depressionRaw: allAirtable(filter: { table: { eq: "Depression" } }) {
        edges {
          node {
            data {
              QuestionNumber
              QuestionText
            }
          }
        }
      }
      stressRaw: allAirtable(filter: { table: { eq: "Stress" } }) {
        edges {
          node {
            data {
              QuestionNumber
              QuestionText
            }
          }
        }
      }
      minorityRaw: allAirtable(filter: { table: { eq: "Minority" } }) {
        edges {
          node {
            data {
              QuestionNumber
              QuestionText
            }
          }
        }
      }
    }
  `);

  const getQuestions = (selectedCategory: Categories): AirtableData[] => {
    switch (selectedCategory) {
      case 'anxiety':
        return anxietyQuestionsRaw.map(x => x.node.data as AirtableData);
      case 'depression':
        return depressionQuestionsRaw.map(x => x.node.data as AirtableData);
      case 'stress':
        return stressQuestionsRaw.map(x => x.node.data as AirtableData);
      case 'minority':
        return minorityQuestionsRaw.map(x => x.node.data as AirtableData);
      default:
        throw new Error('Unhandled case.');
    }
  };

  const questions = getQuestions(category);
  const sortedQuestions = questions.sort(x => x.QuestionNumber);

  const totalQuestions = questions.length;

  // @TODO: NOTES:  Don't call setState for a component that is about to die.
  const [user, authLoading, error] = useAuthState(getFirebaseAuth());

  const db = getFirebaseFirestore();

  const [loading, setLoading] = useState<boolean>(false);
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState<number>(1);
  const [score, setScore] = useState<number>(0);
  const [responses, setResponses] = useState<
    { questionNumber: number; score: number }[]
  >([]);

  const answerQuestion = async (answer: number): Promise<void> => {
    setScore(score + answer);
    setResponses([
      ...responses,
      { questionNumber: currentQuestionNumber, score: answer },
    ]);
    if (currentQuestionNumber !== totalQuestions) {
      setCurrentQuestionNumber(currentQuestionNumber + 1);
    }
  };

  useEffect(() => {
    if (responses.length >= totalQuestions) {
      setLoading(true);
      const severityLevel = getSeverityLevel(score);
      const timestamp = new Date().toISOString();

      const ref = db
        .collection('users')
        .doc(user.uid)
        .collection('wellbeingResults')
        .doc(category)
        .collection('categoryResponses')
        .doc(timestamp);
      ref
        .set({
          creationDate: timestamp,
          responses,
          severityLevel,
          totalScore: score,
        })
        .then(() => {
          gatsbyNavigate(`/result/${category}-${severityLevel}`);
        });
    }
  }, [
    currentQuestionNumber,
    category,
    db,
    responses,
    score,
    totalQuestions,
    user.uid,
  ]);

  const [introModal, toggleIntroModal] = useModali({
    animated: true,
    centered: true,
    overlayClose: false,
    closeButton: false,
  });

  const [modalPage, setModalPage] = useState<number>(1);

  useEffect(() => {
    toggleIntroModal();
    return () => {
      if (typeof window !== 'undefined') {
        document.body.classList.remove('modali-open');
      }
    };
  }, []);

  if (loading) {
    return (
      <Container
        fluid
        className="w-100 vh-100 px-0 d-flex flex-column justify-content-center align-items-center position-absolute"
        style={{ backgroundColor: '#3d8371' }}
      >
        <div className="sk-bounce">
          <div className="sk-bounce-dot bg-white" />
          <div className="sk-bounce-dot bg-white" />
        </div>
      </Container>
    );
  }

  return (
    <Layout contrast>
      <ParticlesBackground particlesColour={getCategoryColour(category)}>
        <Container
          fluid
          className="position-absolute min-vh-100 vw-100 px-5 d-flex flex-column align-items-center"
          style={{ zIndex: 10 }}
        >
          <Row className="pt-3 pt-sm-5">
            <Col xs={{ size: 6, offset: 3 }}>
              <CircularProgressbar
                value={(currentQuestionNumber / totalQuestions) * 100}
                text={`Q${currentQuestionNumber}`}
                strokeWidth={14}
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  rotation: 0,

                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: 'butt',

                  // Text size
                  textSize: '24px',

                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 0.5,

                  // Can specify path transition in more detail, or remove it entirely
                  // pathTransition: 'none',

                  // Colors
                  pathColor: `white`,
                  textColor: 'white',
                  trailColor: 'rgba(214, 214, 214, 0.4)',
                  backgroundColor: 'red',
                })}
              />
            </Col>
          </Row>
          <Row>
            <Col className="text-center mt-3">
              <h2
                style={{ fontFamily: 'Montserrat' }}
                className="text-white d-none d-sm-block"
              >
                {getFormattedCategoryTitle(category)} Test
              </h2>
              <hr className="border-white d-none d-sm-block" />
              <Text
                fontFamily="heading"
                fontSize={2}
                color="white"
                textAlign="center"
                pb={3}
              >
                {sortedQuestions[currentQuestionNumber - 1].QuestionText}
              </Text>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column text-center align-items-center justify-content-center">
              <AwesomeButton
                style={{ width: 200 }}
                type="secondary"
                className="mb-3"
                onPress={() => answerQuestion(0)}
              >
                Not at all
              </AwesomeButton>
              <AwesomeButton
                style={{ width: 200 }}
                type="secondary"
                className="mb-3"
                onPress={() => answerQuestion(1)}
              >
                Sometimes
              </AwesomeButton>
              <AwesomeButton
                style={{ width: 200 }}
                type="secondary"
                className="mb-3"
                onPress={() => answerQuestion(2)}
              >
                Regularly
              </AwesomeButton>
              <AwesomeButton
                style={{ width: 200 }}
                type="secondary"
                className="mb-3"
                onPress={() => answerQuestion(3)}
              >
                Most of the time
              </AwesomeButton>
            </Col>
          </Row>
        </Container>
        <Modali.Modal {...introModal}>
          <Box
            flexBox
            width="100%"
            height="100%"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            px={4}
            py={4}
          >
            {modalPage === 1 && (
              <>
                <Text
                  m={0}
                  fontSize={2}
                  fontWeight={3}
                  fontFamily="heading"
                  pb={3}
                  textAlign="center"
                >
                  Welcome
                </Text>
                <HorizontalRule />
                <Text
                  m={0}
                  fontSize={0}
                  fontWeight={1}
                  fontFamily="body"
                  pb={3}
                  pt={3}
                  textAlign="center"
                >
                  {`This seven-question wellbeing test is about your level of ${getFormattedCategoryPhrase(
                    category
                  )}.`}
                </Text>
                {category === 'minority' && (
                  <Text
                    m={0}
                    fontSize={0}
                    fontWeight={1}
                    fontFamily="body"
                    pb={3}
                    textAlign="center"
                  >
                    Minority stress refers to specific stress factors relating
                    to your sexual and/or gender identity as an LGBTQ+ person.
                  </Text>
                )}
                <Button
                  px={4}
                  mt={3}
                  onClick={() => {
                    setModalPage(2);
                  }}
                >
                  <Text as="span">Let's Begin</Text>
                </Button>
              </>
            )}
            {modalPage === 2 && (
              <>
                <Text
                  m={0}
                  fontSize={2}
                  fontWeight={3}
                  fontFamily="heading"
                  pb={3}
                  textAlign="center"
                >
                  Welcome
                </Text>
                <HorizontalRule />
                <Text
                  m={0}
                  fontSize={0}
                  fontWeight={1}
                  fontFamily="body"
                  pb={3}
                  pt={3}
                  textAlign="center"
                >
                  Over the last two weeks, how often have you been bothered by
                  the following?
                </Text>
                <Button px={4} mt={3} onClick={toggleIntroModal}>
                  <Text as="span">Next</Text>
                </Button>
              </>
            )}
          </Box>
        </Modali.Modal>
      </ParticlesBackground>
    </Layout>
  );
};

export default DiagnosisQuizPage;
